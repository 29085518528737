import { apiCall } from "../../../Middleware/API/apiRequest";
import { getTokensFromStorage } from "../../../Utils/Auth/AuthToken";
import { AUTEST_RUN_SET_DETAIL } from "../../../constants/apiRoutes";

/**
 * list Run Sets Detail.
 * @returns {Promise} - A promise that resolves with the list of Run Sets or rejects with an error.
 * @param props
 */
export const listRunSetDetail = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
  uc_ossi_run_id,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset == 0 ? 1 : offset,
    pageSize: limit,
    sortField: sort,
  };

  const response = await apiRequest({
    url: `${AUTEST_RUN_SET_DETAIL.replace("{runId}", uc_ossi_run_id)}/Filter`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return {
    runSetDetail: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create Run Set Detail.
 * @returns {Promise} - A promise that resolves with the creation of Run Set or rejects with an error.
 * @param data
 */
export const createRunSetDetail = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
  uc_ossi_run_id,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_RUN_SET_DETAIL.replace("{runId}", uc_ossi_run_id)}`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * delete Run Set.
 * @returns {Promise} - A promise that resolves with the update of Run Set or rejects with an error.
 * @param data
 */
export const deleteRunSetDetail = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
  uc_ossi_run_id,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_RUN_SET_DETAIL.replace("{runId}", uc_ossi_run_id)}?uc_ossi_prod_id=${data?.uc_ossi_prod_id}&tenantid=${data?.tenant_id}&seqnumber=${data?.uc_ossi_run_seq}`,
    method: "DELETE",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Run Set Detail Order.
 * @returns {Promise} - A promise that resolves with the creation of Run Set or rejects with an error.
 * @param data
 */
export const updateRunSetDetailOrder = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
  uc_ossi_run_id,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_RUN_SET_DETAIL.replace("{runId}", uc_ossi_run_id)}/Order`,
    method: "PUT",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Run Set Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of Run Set or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
    if (response.Message) {
      return { isSuccess: false, errorMessage: response.Message };
    } else if (!response.IsSuccess) {
      return { isSuccess: false, errorMessage: response.StatusDesc };
    } else {
      return response;
    }
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
